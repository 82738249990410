import React, { useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; // Icons

function ExpiringProducts() {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 5;

  const products = [
    { name: 'Aşı 1', daysLeft: 2 },
    { name: 'Aşı 2', daysLeft: 5 },
    { name: 'İlaç 4', daysLeft: -1 }, // Süresi geçmiş
    { name: 'İlaç 1', daysLeft: 45 },
    { name: 'İlaç 2', daysLeft: 7 },
    { name: 'Aşı 3', daysLeft: 60 },
    { name: 'İlaç 3', daysLeft: 1 },
    { name: 'Aşı 4', daysLeft: 10 },
    { name: 'Aşı 5', daysLeft: 30 },
    { name: 'İlaç 5', daysLeft: 3 },
  ];

  const getColor = (daysLeft) => {
    if (daysLeft < 0) return 'text-red-600 font-semibold'; // Süresi geçmişse kırmızı
    if (daysLeft <= 7) return 'text-orange-600 font-semibold'; // 7 gün veya daha az kaldıysa turuncu
    if (daysLeft <= 30) return 'text-yellow-600 font-semibold'; // 30 gün veya daha az kaldıysa sarı
    return 'text-green-600 font-semibold'; // 30 günden fazla kaldıysa yeşil
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="border border-gray-300 p-6 rounded-lg shadow-sm">
      <h3 className="font-semibold mb-4 text-gray-700">Son Kullanma Tarihi Gelen Ürünler</h3>
      <table className="w-full text-left border-collapse">
        <thead>
          <tr>
            <th className="border-b-2 px-4 py-2 text-gray-600">Ürün</th>
            <th className="border-b-2 px-4 py-2 text-gray-600">Kalan Gün</th>
            <th className="border-b-2 px-4 py-2 text-gray-600">İşlem</th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.map((product, index) => (
            <tr key={index} className="border-t">
              <td className="px-4 py-2">{product.name}</td>
              <td className={`px-4 py-2 ${getColor(product.daysLeft)}`}>
                {product.daysLeft > 0 ? `${product.daysLeft} gün kaldı` : 'Süresi Geçti'}
              </td>
              <td className="px-4 py-2 flex justify-around">
                <button className="text-blue-600 hover:text-blue-700">
                  <FaEdit />
                </button>
                <button className="text-red-600 hover:text-red-700">
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-2 border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          Önceki
        </button>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(products.length / productsPerPage)}
          className="px-4 py-2 mx-2 border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          Sonraki
        </button>
      </div>
    </div>
  );
}

export default ExpiringProducts;
