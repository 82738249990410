import React from 'react';

function VeterinariansScreen() {
  const veterinarians = [
    { id: 1, name: 'Dr. Smith', specialty: 'Surgery', availability: 'Mon-Fri' },
    { id: 2, name: 'Dr. Johnson', specialty: 'Dentistry', availability: 'Tue-Thu' },
  ];

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Veterinarians</h2>
      <ul>
        {veterinarians.map((vet) => (
          <li key={vet.id} className="mb-2">
            {vet.name} - {vet.specialty} (Available: {vet.availability})
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VeterinariansScreen;
