import React from "react";
import {
  FaCalendarCheck,
  FaDollarSign,
  FaUsers,
  FaTasks,
} from "react-icons/fa"; 

function StatisticsWidgets() {
  return (
    <>
    <div className="grid grid-cols-2 gap-2 mb-6">
      <div className="relative border p-4 rounded-lg shadow-sm">
        <div className="absolute -top-6 left-2 bg-white p-2 rounded-full shadow-sm">
          <FaUsers className="text-blue-500 text-2xl" />
        </div>
        <div className="mt-4">
          <h3 className="font-semibold text-lg">Toplam Sahip/Hayvan</h3>
          <p className="text-xl font-bold mt-1">150</p>
          <p className="text-sm text-gray-500">4 Onaylanmadı</p>
        </div>
      </div>

      <div className="relative border p-4 rounded-lg shadow-sm">
        <div className="absolute -top-6 left-2 bg-white p-2 rounded-full shadow-sm">
          <FaDollarSign className="text-green-500 text-2xl" />
        </div>
        <div className="mt-4">
          <h3 className="font-semibold text-lg">Finans</h3>
          <p className="text-xl font-bold mt-1">$3,450</p>
          <p className="text-sm text-green-500">Geçen aya göre +%5.5</p>
        </div>
      </div>

      
      
    </div>
    <div className="grid grid-cols-2 gap-2">
    <div className="relative border p-4 rounded-lg shadow-sm">
        <div className="absolute -top-6 left-2 bg-white p-2 rounded-full shadow-sm">
          <FaTasks className="text-orange-500 text-2xl" />
        </div>
        <div className="mt-4">
          <h3 className="font-semibold text-lg">Bekleyen Görevler</h3>
          <p className="text-xl font-bold mt-1">8</p>
          <p className="text-sm text-gray-500">Bugün yapılacak görevler</p>
        </div>
      </div>

      <div className="relative border p-4 rounded-lg shadow-sm">
        <div className="absolute -top-6 left-2 bg-white p-2 rounded-full shadow-sm">
          <FaCalendarCheck className="text-blue-500 text-2xl" />
        </div>
        <div className="mt-4">
          <h3 className="font-semibold text-lg">Yaklaşan Randevular</h3>
          <p className="text-xl font-bold mt-1">120</p>
          <p className="text-sm text-gray-500">4 onaylanmadı</p>
        </div>
      </div>

    </div>
    </>
  );
}

export default StatisticsWidgets;
