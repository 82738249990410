import React from 'react';

function AppointmentsScreen() {
  const appointments = [
    { id: 1, date: '2024-09-01', time: '10:00 AM', pet: 'Bella', owner: 'John Doe', status: 'Scheduled' },
    { id: 2, date: '2024-09-02', time: '11:00 AM', pet: 'Max', owner: 'Jane Doe', status: 'Scheduled' },
  ];

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Appointments</h2>
      <ul>
        {appointments.map((appointment) => (
          <li key={appointment.id} className="mb-2">
            {appointment.date} at {appointment.time} - {appointment.pet} (Owner: {appointment.owner}) - {appointment.status}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AppointmentsScreen;
