import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrashAlt, FaSignOutAlt } from 'react-icons/fa';
import Navbar from '../Navbar';
import PetDetails from './PetDetails';

function PetListScreen() {
  const navigate = useNavigate();
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);

  useEffect(() => {
    axios
      .get('https://api.dev1.fugesoft.com/api/owners')
      .then((response) => {
        const allPets = response.data.flatMap((owner) =>
          owner.pets.map((pet) => ({
            ...pet,
            ownerName: owner.name,
            ownerPhone: owner.phone_number,
            responsibleDoctor: pet.responsibleDoctor || "Belirtilmemiş",
          }))
        );
        setPets(allPets);
      })
      .catch((error) => console.error('Error fetching pets:', error));
  }, []);

  const handleRowClick = (pet) => {
    setSelectedPet(pet);
  };

  const handleBackToList = () => {
    setSelectedPet(null);
  };

  return (
    <div className="p-6">
      <Navbar title="Yatan Hasta Listesi" onBack={() => navigate(-1)} />

      {selectedPet ? (
        <PetDetails pet={selectedPet} onBack={handleBackToList} />
      ) : (
        <div>
          <h2 className="text-2xl font-bold mb-4">Yatan Hasta Listesi</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg border">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b text-center">Fotoğraf</th>
                  <th className="px-4 py-2 border-b text-center">Hasta Adı</th>
                  <th className="px-4 py-2 border-b text-center">Sahip Bilgileri</th>
                  <th className="px-4 py-2 border-b text-center">Sorumlu Hekim</th>
                  <th className="px-4 py-2 border-b text-center">Tanı</th>
                  <th className="px-4 py-2 border-b text-center">İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {pets.map((pet) => (
                  <tr key={pet._id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border-b text-center align-middle">
                      <img
                        src={pet.photo || 'https://via.placeholder.com/80'}
                        alt={pet.name}
                        className="h-9 w-9 rounded-full mx-auto"
                      />
                    </td>
                    <td className="px-4 py-2 border-b text-center align-middle" onClick={() => handleRowClick(pet)}>
                      {pet.name} <span className="text-gray-500">({pet.breed})</span>
                    </td>
                    <td className="px-4 py-2 border-b text-center align-middle">
                      {pet.ownerName} - {pet.ownerPhone}
                    </td>
                    <td className="px-4 py-2 border-b text-center align-middle">{pet.responsibleDoctor}</td>
                    <td className="px-4 py-2 border-b text-center align-middle">{pet.diagnosis || 'Yok'}</td>
                    <td className="px-4 py-2 border-b text-center align-middle">
                      <button
                        className="text-green-500 hover:text-green-700 mx-1"
                        title="Taburcu Et"
                        onClick={() => alert(`Taburcu Et: ${pet.name}`)}
                      >
                        <FaSignOutAlt />
                      </button>
                      <button
                        className="text-blue-500 hover:text-blue-700 mx-1"
                        title="Düzenle"
                        onClick={() => alert(`Düzenle: ${pet.name}`)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700 mx-1"
                        title="Sil"
                        onClick={() => alert(`Sil: ${pet.name}`)}
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default PetListScreen;
