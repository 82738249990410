import React, { useState } from 'react';
import { FaPaw, FaSyringe, FaNotesMedical, FaSearch, FaWeight, FaCheck, FaMoneyBillWave, FaList, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';

function PetDetailsScreen() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false); // Aşı takvimi modalı için state
  const [newScheduleModalOpen, setNewScheduleModalOpen] = useState(false); // Yeni aşı takvimi ekleme modalı için state
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [newScheduleTitle, setNewScheduleTitle] = useState(''); // Yeni aşı takvimi başlığı
  const [newVaccinations, setNewVaccinations] = useState([{ week: '', vaccine: '', status: 'Planlandı' }]); // Yeni aşı listesi

  // Aşı planı dummy veriler
  const [vaccinationSchedules, setVaccinationSchedules] = useState([
    {
      id: 1,
      title: 'Yeni Doğmuş Kedi',
      vaccinations: [
        { week: 1, vaccine: 'FVRCP', status: 'Planlandı' },
        { week: 2, vaccine: 'FVRCP (2. doz)', status: 'Planlandı' },
        { week: 3, vaccine: 'FVRCP (3. doz)', status: 'Planlandı' },
        { week: 4, vaccine: 'Feline Leukemia', status: 'Planlandı' },
      ],
    },
    {
      id: 2,
      title: '1 Yaşında Kedi',
      vaccinations: [
        { week: 1, vaccine: 'Rabies (Kuduz)', status: 'Planlandı' },
        { week: 2, vaccine: 'Feline Leukemia', status: 'Planlandı' },
        { week: 3, vaccine: 'Booster (Takviye)', status: 'Planlandı' },
      ],
    },
  ]);

  // Modal açma ve kapama fonksiyonları
  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  const openScheduleModal = () => {
    setScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setScheduleModalOpen(false);
  };

  const openNewScheduleModal = () => {
    setNewScheduleModalOpen(true);
  };

  const closeNewScheduleModal = () => {
    setNewScheduleModalOpen(false);
    setNewScheduleTitle('');
    setNewVaccinations([{ week: '', vaccine: '', status: 'Planlandı' }]);
  };

  // Aşının durumunu güncelleme fonksiyonu
  const markAsDone = (vaccineIndex) => {
    const updatedSchedule = { ...selectedSchedule };
    updatedSchedule.vaccinations[vaccineIndex].status = 'Uygulandı';
    setSelectedSchedule(updatedSchedule);
  };

  // Yeni aşı takvimi ekleme fonksiyonu
  const addNewVaccination = () => {
    setNewVaccinations([...newVaccinations, { week: '', vaccine: '', status: 'Planlandı' }]);
  };

  const handleNewVaccinationChange = (index, field, value) => {
    const updatedVaccinations = [...newVaccinations];
    updatedVaccinations[index][field] = value;
    setNewVaccinations(updatedVaccinations);
  };

  const saveNewSchedule = () => {
    const newSchedule = {
      id: vaccinationSchedules.length + 1,
      title: newScheduleTitle,
      vaccinations: newVaccinations,
    };
    setVaccinationSchedules([...vaccinationSchedules, newSchedule]);
    closeNewScheduleModal();
  };

  const petDetails = {
    id: 101,
    name: 'Bella',
    breed: 'Golden Retriever',
    age: 5,
    weight: '30 kg',
    height: '60 cm',
    description: 'Enerjik ve dost canlısı.',
    treatments: [
      { date: '2024-01-15', type: 'Aşı', details: 'Kuduz Aşısı' },
      { date: '2024-05-20', type: 'Tedavi', details: 'Parazit Tedavisi' },
    ],
    appointments: [
      { date: '2024-09-01', details: 'Genel Kontrol', time: '10:00 AM' },
      { date: '2024-09-03', details: 'Aşı Takvimi', time: '02:00 PM' },
    ],
    analyses: [
      { date: '2024-02-15', type: 'Kan Tahlili', result: 'Normal' },
      { date: '2024-03-20', type: 'İdrar Tahlili', result: 'Normal' },
    ],
    notes: '',
    debt: { totalPaid: '1,500 TL', totalDebt: '500 TL' }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold">Evcil Hayvan Detayları</h2>
      <div className="mt-4 grid grid-cols-3 gap-4">
        {/* Evcil Hayvan Bilgileri Kartı */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md col-span-1">
          <div className="flex">
            <div className="w-2/3">
              <h3 className="text-xl font-bold">{petDetails.name}</h3>
              <p>Cins: {petDetails.breed}</p>
              <p>Yaş: {petDetails.age}</p>
              <p>Kilo: {petDetails.weight}</p>
              <p>Boy: {petDetails.height}</p>
              <p>Açıklama: {petDetails.description}</p>
            </div>
            <div className="w-1/3 flex items-center justify-center">
              <FaPaw className="text-6xl text-gray-400" />
            </div>
          </div>
        </div>

        {/* Butonlar şeklinde kartlar */}
        <div className="col-span-2 grid grid-cols-3 gap-4">
          <button
            onClick={() => openModal('Uygulanan Aşı Takvimi')}
            className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center hover:bg-gray-200"
          >
            <FaSyringe className="text-3xl mb-2" />
            <p>Uygulanan Aşı Takvimi</p>
          </button>
          <button
            onClick={openScheduleModal}  // Aşı takvimleri modalını açacak
            className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center hover:bg-gray-200"
          >
            <FaList className="text-3xl mb-2" />
            <p>Aşı Takvimleri</p>
          </button>
          <button
            onClick={() => openModal('Yapılan Muayeneler')}
            className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center hover:bg-gray-200"
          >
            <FaNotesMedical className="text-3xl mb-2" />
            <p>Yapılan Muayeneler</p>
          </button>
        </div>

        {/* Kilo Değişimi ve Doktor Notları */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md col-span-1">
          <h3 className="text-xl font-bold">Kilo Değişimi</h3>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <FaWeight className="inline-block mr-2" />
            <p>Grafik alanı</p>
          </div>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md col-span-2">
          <h3 className="text-xl font-bold">Doktor Notları</h3>
          <textarea
            className="w-full p-2 border rounded-md"
            rows="4"
            placeholder="Doktor notları..."
            value={petDetails.notes}
          />
        </div>

        {/* Randevu Geçmişi/Geleceği ve Borç/Tahsilat */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md col-span-2">
          <h3 className="text-xl font-bold">Randevu Geçmişi/Geleceği</h3>
          <div className="grid grid-cols-2 gap-4">
            <table className="min-w-full bg-white shadow-md rounded-lg mt-2">
              <thead>
                <tr>
                  <th className="px-4 py-2">Tarih</th>
                  <th className="px-4 py-2">Detaylar</th>
                  <th className="px-4 py-2">Saat</th>
                  <th className="px-4 py-2">İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {petDetails.appointments.map((appointment, index) => (
                  <tr key={index} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">{appointment.date}</td>
                    <td className="px-4 py-2">{appointment.details}</td>
                    <td className="px-4 py-2">{appointment.time}</td>
                    <td className="px-4 py-2 flex space-x-2">
                      <button className="text-red-500 hover:underline">Sil</button>
                      <button className="text-blue-500 hover:underline">Düzenle</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-bold">Borç/Tahsilat</h3>
              <p><FaMoneyBillWave className="inline-block mr-2" /> Toplam Ödenen: {petDetails.debt.totalPaid}</p>
              <p><FaMoneyBillWave className="inline-block mr-2" /> Toplam Borç: {petDetails.debt.totalDebt}</p>
            </div>
          </div>
          <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600">
            Randevu Ekle
          </button>
        </div>
      </div>

      {/* Aşı Takvimleri Modal */}
      <Modal
        isOpen={scheduleModalOpen}
        onRequestClose={closeScheduleModal}
        contentLabel="Aşı Takvimleri"
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-bold mb-4">Aşı Takvimleri</h2>
        <div className="flex space-x-4 overflow-x-auto">
          {vaccinationSchedules.map((schedule) => (
            <div
              key={schedule.id}
              className="min-w-[250px] bg-gray-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => setSelectedSchedule(schedule)}
            >
              <h3 className="text-lg font-bold mb-2">{schedule.title}</h3>
              <ul>
                {schedule.vaccinations.slice(0, 2).map((vaccine, index) => (
                  <li key={index} className="text-sm">
                    {vaccine.vaccine} - {vaccine.status}
                  </li>
                ))}
                {schedule.vaccinations.length > 2 && <li className="text-sm">...</li>}
              </ul>
            </div>
          ))}
        </div>
        <button
          onClick={openNewScheduleModal}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center"
        >
          <FaPlus className="mr-2" />
          + Yeni Aşı Takvimi
        </button>
        <button
          onClick={closeScheduleModal}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Kapat
        </button>
      </Modal>

      {/* Seçilen Aşı Takvimi Detayları Modal */}
      {selectedSchedule && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Aşı Takvimi Detayları"
          className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
          <h2 className="text-xl font-bold mb-4">{selectedSchedule.title} Aşı Takvimi</h2>
          <table className="min-w-full bg-white shadow-md rounded-lg mb-4">
            <thead>
              <tr>
                <th className="px-4 py-2">Hafta</th>
                <th className="px-4 py-2">Aşı Adı</th>
                <th className="px-4 py-2">Durum</th>
                <th className="px-4 py-2">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {selectedSchedule.vaccinations.map((vaccine, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="px-4 py-2">{vaccine.week}. Hafta</td>
                  <td className="px-4 py-2">{vaccine.vaccine}</td>
                  <td className="px-4 py-2">{vaccine.status}</td>
                  <td className="px-4 py-2">
                    {vaccine.status === 'Planlandı' && (
                      <button
                        className="text-green-500 hover:text-green-700"
                        onClick={() => markAsDone(index)}
                      >
                        <FaCheck />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={closeModal}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Kapat
          </button>
        </Modal>
      )}

      {/* Yeni Aşı Takvimi Ekleme Modal */}
      <Modal
        isOpen={newScheduleModalOpen}
        onRequestClose={closeNewScheduleModal}
        contentLabel="Yeni Aşı Takvimi"
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-bold mb-4">Yeni Aşı Takvimi Ekle</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Takvim Başlığı</label>
          <input
            type="text"
            value={newScheduleTitle}
            onChange={(e) => setNewScheduleTitle(e.target.value)}
            className="mt-1 p-2 block w-full shadow-sm border border-gray-300 rounded-md"
            placeholder="Örn: 6 Aylık Kedi"
          />
        </div>
        <h3 className="text-lg font-bold mb-2">Aşılar</h3>
        {newVaccinations.map((vaccine, index) => (
          <div key={index} className="mb-2 grid grid-cols-3 gap-4">
            <input
              type="number"
              value={vaccine.week}
              onChange={(e) => handleNewVaccinationChange(index, 'week', e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
              placeholder="Hafta"
            />
            <input
              type="text"
              value={vaccine.vaccine}
              onChange={(e) => handleNewVaccinationChange(index, 'vaccine', e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
              placeholder="Aşı Adı"
            />
            <p className="p-2">Planlandı</p>
          </div>
        ))}
        <button
          onClick={addNewVaccination}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mb-4"
        >
          + Aşı Ekle
        </button>
        <button
          onClick={saveNewSchedule}
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        >
          Kaydet
        </button>
        <button
          onClick={closeNewScheduleModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mt-4"
        >
          Kapat
        </button>
      </Modal>
    </div>
  );
}

export default PetDetailsScreen;
