import React, { useState } from 'react';

const clients = [
  { id: 1, name: 'John Doe', petName: 'Bella' },
  { id: 2, name: 'Jane Smith', petName: 'Max' },
  { id: 3, name: 'Michael Jordan', petName: 'Buddy' },
];

function ChatSidebar({ selectClient, unread }) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-64 bg-gray-800 text-white p-4">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 rounded"
        />
      </div>

      <ul>
        {filteredClients.map((client) => (
          <li
            key={client.id}
            className="p-2 mb-2 cursor-pointer bg-gray-700 hover:bg-gray-600 rounded"
            onClick={() => selectClient(client)}
          >
            <div className="flex justify-between">
              <span>{client.name}</span>
              {unread[client.id] && <span className="text-red-500">•</span>}
            </div>
            <small className="text-gray-400">{client.petName}</small>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ChatSidebar;
