import React, { useState } from 'react';
import {
  FaPhone,
  FaWhatsapp,
  FaNotesMedical,
  FaFileAlt,
} from 'react-icons/fa';
import Modal from 'react-modal';
import KanbanBoard from '../Overview/KanbanBoard';

Modal.setAppElement('#root');

function PetDetails({ onBack }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const pet = {
    name: "Bella",
    ownerName: "John Doe",
    ownerPhone: "1234567890",
    address: "123 Pet St, Petville, Long Address Example City, Some Country, ZIP 12345",
    breed: "Golden Retriever",
    age: 5,
    weight: "30 kg",
    height: "60 cm",
    complaint: "Yok",
    diagnosis: "Belirtilmemiş",
    doctorNotes: "Enerjik ve dost canlısı.",
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const categories = [
    { title: "İstek", icon: <FaFileAlt /> },
    { title: "Ziyaret", icon: <FaFileAlt /> },
    { title: "Finansal", icon: <FaFileAlt /> },
    { title: "Fotoğraf Geçmişi", icon: <FaFileAlt /> },
  ];

  return (
    <div className="p-4 md:p-6">
      {/* Two-Column Row for Pet Information and Category Buttons */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Pet Information Card */}
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
          <div className="grid grid-cols-4 gap-4 text-gray-800">
            {/* 1st Column: Labels for Pet Details */}
            <div className="font-semibold space-y-2">
              <p>Adı:</p>
              <p>Yaş:</p>
              <p>Kilo:</p>
              <p>Boy:</p>
              <p>Şikayeti:</p>
              <p>Teşhis:</p>
            </div>

            {/* 2nd Column: Pet Details */}
            <div className="space-y-2">
              <p>{pet.name}</p>
              <p>{pet.age} yaşında</p>
              <p>{pet.weight}</p>
              <p>{pet.height}</p>
              <p>{pet.complaint}</p>
              <p>{pet.diagnosis}</p>
            </div>

            {/* 3rd Column: Labels for Owner and Contact */}
            <div className="font-semibold space-y-2">
              <p>Sahibi:</p>
              <p>Tel:</p>
              <p>Adres:</p>
              <p>WhatsApp:</p>
              <p>Doktor Yorumu:</p>
              <p>Hasta Fotoğrafları:</p>
            </div>

            {/* 4th Column: Owner and Contact Information */}
            <div className="space-y-2">
              <p>{pet.ownerName}</p>
              <div className="flex items-center">
                <FaPhone className="mr-2 text-gray-600" />
                <span>{pet.ownerPhone}</span>
              </div>
              {/* Address with CSS-based Tooltip */}
              <div className="relative group">
                <p className="truncate cursor-pointer">
                  {pet.address}
                </p>
                <span className="absolute bg-gray-700 text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  {pet.address}
                </span>
              </div>
              <a
                href={`https://wa.me/${pet.ownerPhone}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500 inline-flex items-center"
              >
                <FaWhatsapp className="mr-1" /> WhatsApp
              </a>
              <button
                className="text-blue-600 inline-flex items-center"
                onClick={() => openModal("Doktor Notları")}
              >
                <FaNotesMedical className="mr-1" /> {pet.doctorNotes}
              </button>
              <button
                className="text-gray-600 inline-flex items-center"
                onClick={() => openModal("Hasta Fotoğrafları")}
              >
                <FaFileAlt className="mr-1" /> Fotoğrafları Görüntüle
              </button>
            </div>
          </div>
        </div>

        {/* Category Buttons - 2 Columns, 2 Rows */}
        <div className="grid grid-cols-2 gap-4">
          {categories.map((category, index) => (
            <div
              key={index}
              className="bg-gray-200 p-4 rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-300 transition"
              onClick={() => openModal(category.title)}
            >
              <span className="text-center font-semibold flex flex-col items-center">
                <div className="text-3xl mb-2">{category.icon}</div>
                {category.title}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Kanban Board Below */}
      <div className="mt-6">
        <KanbanBoard showNavbar={false} />
      </div>

      {/* Modal for Category Buttons */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg relative"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <button onClick={closeModal} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-xl">
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4">{modalContent}</h2>
        <p>Content for {modalContent} goes here.</p>
      </Modal>
    </div>
  );
}

export default PetDetails;
