import React from 'react';
import moment from 'moment';

function MessageItem({ message }) {
  const isClient = message.sender === 'client';

  return (
    <div className={`mb-4 flex ${isClient ? 'justify-start' : 'justify-end'}`}>
      <div
        className={`p-3 rounded-lg ${isClient ? 'bg-gray-200' : 'bg-blue-500 text-white'}`}
      >
        <p>{message.text}</p>
        <small className="block mt-2 text-sm text-gray-600">
          {moment(message.timestamp).format('HH:mm')}
        </small>
      </div>
    </div>
  );
}

export default MessageItem;
