import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import LoginScreen from './screens/LoginScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import OverviewScreen from './screens/Overview/OverviewScreen';
import PetOwnerScreen from './screens/PetOwnerScreen';
import PetOwnerDetailsScreen from './screens/PetOwnerDetailsScreen';
import PetDetailsScreen from './screens/PetDetailsScreen';
import AppointmentsScreen from './screens/AppointmentsScreen';
import PaymentsScreen from './screens/PaymentsScreen';
import VeterinariansScreen from './screens/VeterinariansScreen';
import CalendarScreen from './screens/Calender/CalenderScreen'; // Calendar Screen imported
import MessagingScreen from './screens/MessagingScreen/MessagingScreen'; // Messaging Screen imported
import StockManagementScreen from './screens/StockManagementScreen/StockManagementScreen'; // Stok Yönetim ekranı eklendi
import OverviewKanban from './screens/OverviewKanban/OverviewKanban';
import PetList from './screens/PetList';
import KanbanBoard from './screens/Overview/KanbanBoard';
import PetListScreen from './screens/OverviewKanban/PetListScreen';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1">
          
          <div className="flex-1 p-4">
            <Routes>
              <Route path="/" element={<OverviewKanban />} />
              <Route path="/change-password" element={<ChangePasswordScreen />} />
              <Route path="/overview" element={<OverviewScreen />} />
              <Route path="/patients" element={<PetOwnerScreen />} />
              <Route path="/pet-owner/:owner_id" element={<PetOwnerDetailsScreen />} />
              <Route path="/pet-details/:id" element={<PetDetailsScreen />} />
              <Route path="/appointments" element={<AppointmentsScreen />} />
              <Route path="/calendar" element={<CalendarScreen />} />
              <Route path="/payments" element={<PaymentsScreen />} />
              <Route path="/veterinarians" element={<VeterinariansScreen />} />
              <Route path="/messaging" element={<MessagingScreen />} /> {/* Messaging screen added */}
              <Route path="/stock-management" element={<StockManagementScreen />} /> {/* Stok Yönetim rotası */}
              <Route path="/anasayfa" element={<OverviewKanban />} /> {/* Stok Yönetim rotası */}
              <Route path="/petlist" element={<PetList />} /> {/* Stok Yönetim rotası */}
              <Route path="/kanban-board" element={<KanbanBoard />} />
              <Route path="/pet-list" element={<PetListScreen />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
