import React from 'react';
import ActionButtonKanban from './ActionButtonKanban';

function OverviewKanban() {
  return (
    <div className="p-4 min-h-screen flex flex-col items-center">
      <div className="bg-gray-100 p-4 shadow-md w-full max-w-4xl flex items-center justify-center mb-8">
        <h1 className="text-2xl font-semibold text-blue-600 text-center">Vet Clinic Dashboard</h1>
      </div>
      <div className="flex justify-center w-full">
        <div className="max-w-4xl w-full">
          <ActionButtonKanban />
        </div>
      </div>
    </div>
  );
}

export default OverviewKanban;
