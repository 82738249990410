import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {FaCommentDots,FaAddressBook, FaCalendarPlus, FaUserPlus, FaShoppingCart, FaFileAlt, FaRandom, FaSyncAlt, FaBed, FaJenkins, FaMedkit } from 'react-icons/fa';
import { FaListCheck } from "react-icons/fa6";
import { MdOutlinePets } from "react-icons/md";

import PetListModal from './PetListModal';

function ActionButtonKanban() {
  const navigate = useNavigate();
  const [isPetListModalOpen, setIsPetListModalOpen] = useState(false);

  const openPetListModal = () => setIsPetListModalOpen(true);
  const closePetListModal = () => setIsPetListModalOpen(false);

  const buttons = [
    { icon: <FaListCheck />, label: "Yapılacaklar", path: "/kanban-board", borderColor: "border-blue-400" },
    { icon: <FaBed />, label: "Yatan Hasta Listesi", path: "/pet-list", borderColor: "border-blue-400" },
    { icon: <FaMedkit />, label: "Ayakta Tedavi", path: "/outpatient", borderColor: "border-blue-400" },
    { icon: <FaFileAlt />, label: "Stok", path: "/stock-management", borderColor: "border-blue-400" },
    { icon: <FaAddressBook />, label: "Müşteriler", path: "/patients", borderColor: "border-green-300" },
    { icon: <MdOutlinePets />, label: "Hayvan Listesi", path: "/petlist", borderColor: "border-green-400" },
    { icon: <FaCommentDots />, label: "Chat", path: "/messaging", borderColor: "border-green-400" },
    { icon: <FaShoppingCart />, label: "Finans", path: "/payments", borderColor: "border-green-400" },
   
  ];

  return (
    <div className="p-6 ">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => navigate(button.path)}
            className={`flex flex-col items-center justify-center border-2 ${button.borderColor} p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 hover:bg-blue-50`}
          >
            <div className="text-4xl mb-2 text-gray-700">{button.icon}</div>
            <span className="text-lg font-semibold text-gray-700">{button.label}</span>
          </button>
        ))}
      </div>

      {/* PetList Modal */}
      <PetListModal isOpen={isPetListModalOpen} onRequestClose={closePetListModal} />
    </div>
  );
}

export default ActionButtonKanban;
