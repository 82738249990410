import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaCalendarPlus, FaUserPlus, FaShoppingCart, FaFileAlt, FaRandom, FaSyncAlt, FaBed, FaJenkins, FaMedkit } from 'react-icons/fa';
import { FaListCheck } from "react-icons/fa6";

function Navbar({ title }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Define fallback titles based on the path if no title prop is provided
  const getTitle = () => {
    if (title) return title; // Use the title prop if provided
    if (location.pathname === '/anasayfa') return 'Overview';
    if (location.pathname === '/kanban-board') return 'Yapılacaklar (Kanban Board)';
    if (location.pathname === '/pet-list') return 'Yatan Hasta Listesi';
    if (location.pathname === '/stock-management') return 'Stok Yönetimi';
    if (location.pathname === '/patients') return 'Müşteri Listesi';
    if (location.pathname === '/messaging') return 'Chat';
    if (location.pathname === '/payments') return 'Ödemeler';
    if (location.pathname === '/petlist') return 'Hayvan Listesi';

    return '';
  };

  // Navigation buttons for each action
  const actionButtons = [
    { icon: <FaListCheck />, label: "Yapılacaklar", path: "/kanban-board" },
    { icon: <FaBed />, label: "Yatan Hasta", path: "/pet-list" },
    { icon: <FaMedkit />, label: "Ayakta Tedavi", path: "/outpatient" },
    { icon: <FaCalendarPlus />, label: "Randevu", path: "/appointments" },
  ];

  return (
    <div className="bg-gray-100 p-4 shadow-md flex items-center justify-between mb-8">
      {/* Back Button - Always navigates to anasayfa */}
      {location.pathname !== '/anasayfa' && (
        <button
          onClick={() => navigate('/anasayfa')}
          className="text-blue-600 hover:text-blue-800 transition-colors flex items-center"
        >
          <FaArrowLeft className="mr-2" />
          <span className="font-medium">Anasayfa</span>
        </button>
      )}

      {/* Centered Title */}
      <h1 className="text-xl font-semibold text-blue-600 flex-1 text-center">
        {getTitle()}
      </h1>

      {/* Action Buttons */}
      <div className="flex space-x-2">
        {actionButtons.map((button, index) => (
          <button
            key={index}
            onClick={() => navigate(button.path)}
            className="flex items-center p-2 text-blue-600 hover:text-blue-800 transition-colors"
            title={button.label}
          >
            {button.icon}
            <span className="hidden sm:inline ml-1">{button.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
