import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";
import { FaEdit, FaTrash } from "react-icons/fa";
import moment from "moment";
import "moment/locale/tr"; 

Modal.setAppElement("#root");

function AppointmentsSchedule() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); 
  const [randevular, setRandevular] = useState([
    {
      saat: "8:00 - 8:30",
      baslik: "Kısırlaştırma işlemi",
      hasta: "Carla Middler",
      renk: "border-l-4 border-red-500",
    },
    {
      saat: "9:00 - 9:30",
      baslik: "Kontrol ve bakım",
      hasta: "Edward Johanson",
      renk: "border-l-4 border-blue-500",
    },
    {
      saat: "9:30 - 10:00",
      baslik: "Aşı, kilo kontrolü",
      hasta: "Ellie Rogers",
      renk: "border-l-4 border-purple-500",
    },
    {
      saat: "10:30 - 11:00",
      baslik: "Kulakta yaralanma, tedavi",
      hasta: "Carla Middler",
      renk: "border-l-4 border-green-500",
    },
    {
      saat: "11:00 - 11:45",
      baslik: "Yaralı ameliyat",
      hasta: "Lily Brown",
      renk: "border-l-4 border-red-500",
    },
  ]);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [filter, setFilter] = useState("");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openEditModal = (randevu) => {
    setSelectedAppointment(randevu);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const deleteAppointment = (randevuToDelete) => {
    setRandevular((prevRandevular) =>
      prevRandevular.filter((randevu) => randevu !== randevuToDelete)
    );
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredRandevular = randevular.filter((randevu) =>
    filter ? randevu.renk.includes(filter) : true
  );

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <h2 className="text-2xl font-bold mb-4">Randevu Planlayıcı</h2>

      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <button
            onClick={() => setSelectedDate(moment(selectedDate).subtract(1, "days").toDate())}
            className="px-4 py-2 border rounded-lg hover:bg-gray-300"
          >
            &lt; Geri
          </button>
          <p className="mx-4">
            {moment(selectedDate).locale("tr").format("LL")}
          </p>
          <button
            onClick={() => setSelectedDate(moment(selectedDate).add(1, "days").toDate())}
            className="px-4 py-2 border rounded-lg hover:bg-gray-300"
          >
            İleri &gt;
          </button>
        </div>
        <p
          onClick={toggleCalendar}
          className="ml-1 text-blue-500 hover:text-blue-700 cursor-pointer"
        >
          {showCalendar ? "Gizle" : "Takvimi Aç"}
        </p>
      </div>

      {showCalendar && (
        <Calendar
          className="w-full border-2 border-gray-300 rounded-lg mb-4"
          locale="tr-TR"
          onChange={onDateChange}
          value={selectedDate}
        />
      )}

      <div className="flex items-center mb-4">
        <button
          onClick={openModal}
          className="bg-green-500 text-white py-2 px-4 rounded-lg shadow-sm hover:bg-green-600 mr-4"
        >
          Yeni Randevu Ekle
        </button>

        <select
          value={filter}
          onChange={handleFilterChange}
          className="px-4 py-2 border rounded-lg"
        >
          <option value="">Hepsi</option>
          <option value="red">Kırmızı</option>
          <option value="blue">Mavi</option>
          <option value="purple">Mor</option>
          <option value="green">Yeşil</option>
        </select>
      </div>

      <div className="mt-6 space-y-4">
        {filteredRandevular.map((randevu, index) => (
          <div
            key={index}
            className={`flex justify-between items-center p-4 rounded-lg shadow-sm ${randevu.renk}`}
          >
            <div>
              <p className="font-semibold">{randevu.baslik}</p>
              <p className="text-gray-500">{randevu.saat}</p>
              <p className="text-gray-700">{randevu.hasta}</p>
            </div>
            <div className="flex space-x-2">
              <FaEdit
                className="text-blue-500 cursor-pointer hover:text-blue-600"
                onClick={() => openEditModal(randevu)}
              />
              <FaTrash
                className="text-red-500 cursor-pointer hover:text-red-600"
                onClick={() => deleteAppointment(randevu)}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Yeni randevu ekleme için Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "30%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
        contentLabel="Yeni Randevu Ekle"
      >
        <h2 className="text-xl font-bold mb-4">Yeni Randevu Ekle</h2>

        <div className="mb-4">
          <label className="block mb-2">Başlık</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Randevu Başlığı"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Hasta</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Hasta Adı"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Saat</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Saat"
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="bg-gray-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-gray-700"
          >
            İptal
          </button>
          <button className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700">
            Ekle
          </button>
        </div>
      </Modal>

      {/* Varolan randevuyu düzenlemek için Modal */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        style={{
          content: {
            top: "30%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
        contentLabel="Randevuyu Düzenle"
      >
        <h2 className="text-xl font-bold mb-4">Randevuyu Düzenle</h2>

        <div className="mb-4">
          <label className="block mb-2">Başlık</label>
          <input
            type="text"
            value={selectedAppointment?.baslik}
            className="w-full p-2 border rounded"
            placeholder="Randevu Başlığı"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Hasta</label>
          <input
            type="text"
            value={selectedAppointment?.hasta}
            className="w-full p-2 border rounded"
            placeholder="Hasta Adı"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Saat</label>
          <input
            type="text"
            value={selectedAppointment?.saat}
            className="w-full p-2 border rounded"
            placeholder="Saat"
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={closeEditModal}
            className="bg-gray-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-gray-700"
          >
            İptal
          </button>
          <button className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700">
            Kaydet
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AppointmentsSchedule;
