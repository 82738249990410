import React, { useState } from 'react';
import ChatSidebar from './ChatSidebar';
import MessageItem from './MessageItem';
import Navbar from '../Navbar'; 

function MessagingScreen() {
  const [selectedClient, setSelectedClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [unread, setUnread] = useState({});

  // Varsayılan dummy mesajlar (Türkçe)
  const dummyMessages = {
    1: [
      { text: 'Merhaba veteriner bey, Bella’nın aşıları ne zaman yapılmalı?', sender: 'client', timestamp: new Date() },
      { text: 'Merhaba, Bella’nın aşıları önümüzdeki hafta yapılabilir.', sender: 'clinic', timestamp: new Date() }
    ],
    2: [
      { text: 'Max’in kısırlaştırma operasyonu için ne yapmam gerekiyor?', sender: 'client', timestamp: new Date() },
      { text: 'Sadece randevu almanız yeterli, detayları konuşuruz.', sender: 'clinic', timestamp: new Date() }
    ],
    3: [
      { text: 'Buddy’nin iştahı kesildi, bir sorun olabilir mi?', sender: 'client', timestamp: new Date() },
      { text: 'Detaylı muayene için kliniğimize getirin, gerekli testleri yaparız.', sender: 'clinic', timestamp: new Date() }
    ],
  };

  // Handle selecting a client conversation
  const selectClient = (client) => {
    setSelectedClient(client);
    setMessages(dummyMessages[client.id] || []); // Dummy mesajları getir
    setUnread((prevUnread) => ({ ...prevUnread, [client.id]: false })); // Mesajları okundu olarak işaretle
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const message = {
      text: newMessage,
      sender: 'clinic',
      timestamp: new Date(),
    };

    setMessages((prevMessages) => [...prevMessages, message]);
    setNewMessage(''); // Giriş alanını temizle
  };

  return (
    <div className="h-full justify-center items-center bg-gray-100">
      <Navbar/>
      <div className="bg-white shadow-lg rounded-lg w-2/4 h-3/5 flex overflow-hidden">
        <ChatSidebar selectClient={selectClient} unread={unread} />

        <div className="flex-1 flex flex-col">
          {selectedClient ? (
            <>
              <div className="flex items-center justify-between bg-gray-800 p-4 text-white">
                <h2 className="text-xl font-bold">{selectedClient.name}</h2>
                <span>{selectedClient.petName}</span>
              </div>

              <div className="flex-1 p-4 overflow-auto bg-gray-100">
                {messages.map((message, index) => (
                  <MessageItem key={index} message={message} />
                ))}
              </div>

              <div className="p-4 bg-white flex items-center space-x-4">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Mesaj yaz..."
                  className="flex-1 p-2 border rounded"
                />
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Gönder
                </button>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center">
              <h2 className="text-xl">Bir sohbet seçin...</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessagingScreen;
