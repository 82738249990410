import React from 'react';
import { FaCalendarPlus, FaUserPlus, FaShoppingCart, FaFileAlt, FaRandom, FaSyncAlt } from 'react-icons/fa';

function ActionButtons() {
  return (
    <div className="grid grid-cols-4 gap-2">
      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaCalendarPlus className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Randevu Oluştur</span>
      </button>

      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaUserPlus className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Yeni Hasta Ekle</span>
      </button>

      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaShoppingCart className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Satış Yap</span>
      </button>

      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaFileAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Aylık Rapor</span>
      </button>

      <button className="border-2 border-green-300 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaRandom className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">İstem Oluştur</span>
      </button>

      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Order Oluştur</span>
      </button>
      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Örnek İşlem 2</span>
      </button>
      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Örnek İşlem 2</span>
      </button>
    </div>
  );
}

export default ActionButtons;
