import React from 'react';
import HeaderSection from './HeaderSection';
import StatisticsWidgets from './StatisticsWidgets';
import ActionButtons from './ActionButtons';
import AppointmentsSchedule from './AppointmentsSchedule';
import ExpiringProducts from './ExpiringProducts';
import KanbanBoard from './KanbanBoard'; // Import KanbanBoard

function OverviewScreen() {
  return (
    <div className="p-4">
      <div className="flex justify-between items-start space-x-4">
        <div className="flex-1">
         
          <div className="mt-6">
            
            <div className="mt-6 flex space-x-4">
            <div className="w-1/2">
            <StatisticsWidgets />
            </div>
              <div className="w-1/2">
                <ActionButtons />
              </div>
            </div>
            <div className="mt-6">
              <KanbanBoard /> {/* Add KanbanBoard component */}
            </div>
            <div className="mt-6 flex space-x-4">
              <div className="w-1/2">
                <ExpiringProducts />
              </div>
              <div className="w-1/2">
                <ActionButtons />
              </div>
            </div>
            <div className="mt-6">
              <KanbanBoard /> {/* Add KanbanBoard component */}
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <AppointmentsSchedule />
        </div>
      </div>
    </div>
  );
}

export default OverviewScreen;
